import firebase from 'firebase/app';
import _ from 'lodash';

export const LocationUtil = {
    getLatitude: (geoaddress) => {
        const latitudeKeys = ['latitude', '_latitude', '_lat'];
        const latitude = parseFloat(findValueByKey(geoaddress, latitudeKeys));
        return isNaN(latitude) ? 0 : latitude;
    },

    getLongitude: (geoaddress) => {
        const longitudeKeys = ['longitude', '_longitude', '_long'];
        const longitude = parseFloat(findValueByKey(geoaddress, longitudeKeys));
        return isNaN(longitude) ? 0 : longitude;
    },

    getGeoaddress: (geoaddress) => {
        // Note:
        // Since the geoaddress passed from the mobile is null,
        // we need to set the geopoint manually here in the website
        let latitude = 0.0;
        let longitude = 0.0;

        if (!_.isEmpty(geoaddress)) {
            latitude = LocationUtil.getLatitude(geoaddress);
            longitude = LocationUtil.getLongitude(geoaddress);
        }

        return new firebase.firestore.GeoPoint(latitude, longitude);
    },

    getAreaRadius: (radius) => {
        let number = 0;
        if (radius) {
            number = parseInt(radius);
        }
        return number;
    },
};

function findValueByKey(obj, keys) {
    for (const key of keys) {
        if (obj && obj[key]) {
            return obj[key];
        }
    }
    return null;
}
